import React from "react";
import styled from "styled-components";
import { lightTheme, darkTheme, Globalstyles } from "../../themes";

const GovernanceIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M2.5 10V15.0013H3.75V22.5H2.5V26.25H22.5L26.25 26.2512V26.25H27.5V22.5H26.25V15.0013H27.5V10L15 2.5L2.5 10ZM7.5 22.5V15.0013H10V22.5H7.5ZM13.75 22.5V15.0013H16.25V22.5H13.75ZM22.5 22.5H20V15.0013H22.5V22.5ZM17.5 10C17.4999 10.3284 17.4352 10.6535 17.3094 10.9569C17.1837 11.2603 16.9994 11.5359 16.7671 11.768C16.5349 12.0002 16.2592 12.1843 15.9557 12.3099C15.6523 12.4355 15.3271 12.5001 14.9987 12.5C14.6704 12.4999 14.3452 12.4352 14.0418 12.3094C13.7385 12.1837 13.4629 11.9994 13.2307 11.7671C12.9986 11.5349 12.8145 11.2592 12.6889 10.9557C12.5633 10.6523 12.4987 10.3271 12.4987 9.99875C12.4989 9.33554 12.7625 8.69957 13.2316 8.23072C13.7007 7.76188 14.3368 7.49858 15 7.49875C15.6632 7.49892 16.2992 7.76253 16.768 8.23161C17.2369 8.70068 17.5002 9.33679 17.5 10V10Z"
          fill="#646775"
        />
      </svg>
    </Div>
  );
};

export default GovernanceIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
