import { createGlobalStyle } from "styled-components";
import Shape from "./assets/bgshape.svg";
import ShapeLight from "./assets/bgshapelight.svg";

export const lightTheme = {
  body: "#F4F7FB",
  bodyAlt: "#E5E7EC",
  backgroundImg: ShapeLight,
  fontColor: "#0F1621",
  fontColorAlt: "#545463",
  fontColorAltHover: "#C44536",
  cardColor: "#FFFFFF",
  cardHover: "#FAFBFD",
  tagBg: "#E8F9F1",
  tagColor: "#32C387",
  filterColor: "brightness(0)",
  announcementFont: "#0556F9",
  announcementBg: "#E0EAFF",
};

export const darkTheme = {
  body: "#21252A",
  bodyAlt: "#1b1f23",
  backgroundImg: Shape,
  fontColor: "#FFFFFF",
  fontColorAltHover: "#FFFFFF",
  fontColorAlt: "#8E90A2",
  cardColor: "#2E3034",
  cardHover: "#282b2f",
  tagBg: "#1e3524",
  tagColor: "#0cb04e",
  filterColor: "brightness(0) invert(1)",
  announcementFont: "#34BFF3",
  announcementBg: "#233540",
};

export const Globalstyles = createGlobalStyle`

body {
    background-color: ${(props) => props.theme.body}

}

`;
