import React, { useState, useEffect } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { lightTheme, darkTheme, Globalstyles } from "./themes.js";

// Components
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import LearnMore from "./components/LearnMore/index";
import Academy from "./components/Academy";
import Gov from "./components/Gov";
import Developers from "./components/Developers";
import Docs from "./components/CallToAction";
import Footer from "./components/Footer";

// Assets
import ParticleOne from "./assets/particleone.svg";
import ParticleTwo from "./assets/particletwo.svg";
import GridGraphic from "./assets/grid.png";

function App() {
  const [theme, setTheme] = useState("dark");
  const [offset, setOffset] = useState(0);
  const [isUpper, setIsUpper] = useState(true);

  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  const handleScroll = () => setOffset(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (offset > 20) {
      setIsUpper(false);
    } else {
      setIsUpper(true);
    }
  }, [offset]);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <Globalstyles />
      <NavContainer className={isUpper ? "" : "active"}>
        <Navbar themeToggler={themeToggler} />
      </NavContainer>
      <TopContainer>
        <Landing />
      </TopContainer>
      <ParticleContainer>
        <Img src={ParticleOne} />
        <Img parttwo src={ParticleTwo} />
      </ParticleContainer>
      <Container>
        <LearnMore />
        <Developers />
        <Gov />
        <Academy />
      </Container>
      <Container call>
        <Docs />
      </Container>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </ThemeProvider>
  );
}

export default App;

const NavContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 0%;
  transform: translateX(-50%);
  z-index: 100;
  &.active {
    background-color: ${(props) => props.theme.body};
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 90px;
  background-image: url(${(props) => props.theme.backgroundImg});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Container = styled.div`
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.bodyAlt};
  ${(props) =>
    props.call &&
    css`
      background-image: url(${GridGraphic});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    `}
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.body};
`;

const ParticleContainer = styled.div`
  position: relative !important;
  @media only screen and (max-width: 1676px) {
    display: none;
  }
`;

const Img = styled.img`
  position: absolute;
  right: 20px;
  top: 350px;
  ${(props) =>
    props.parttwo &&
    css`
      left: 20px;
      top: 950px;
    `}
`;
