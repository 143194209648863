import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

// Components
import KebabMenu from "./kabab";

// Assets
import KebabIcon from "../../assets/Kebab.js";
import Logo from "../../assets/NavbarAssets/Logo.svg";

function Nav({ themeToggler }) {
  const [isToggled, setIsToggled] = useState(false);
  const [isKebabOpen, setIsKebabOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isUpper, setIsUpper] = useState(true);

  const onToggle = () => {
    setIsToggled(!isToggled);
    themeToggler();
  };

  let menuRef = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setIsKebabOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleScroll = () => setOffset(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (offset > 20) {
      setIsUpper(false);
    } else {
      setIsUpper(true);
    }
  }, [offset]);

  return (
    <Header>
      <Container>
        <A href="#">
          <HeaderLogo src={Logo} />
        </A>
        <SubContainer>
          <div>
            <Links href="#Learn">About</Links>
            <Links href="#Gov">Governance</Links>
            <Links href="#Doc">Documentation</Links>
            <Links href="#Acad">Academy</Links>
          </div>
          <ButtonDiv ref={menuRef}>
            {/* Kebab */}
            <KebabMenu
              isKebabOpen={isKebabOpen}
              setIsKebabOpen={setIsKebabOpen}
            />
            {/* Kebab */}
            <ToggleContainer>
              <Checkbox
                type="checkbox"
                id="toggle"
                checked={!isToggled}
                onChange={onToggle}
              />
              <Label htmlFor="toggle" />
            </ToggleContainer>
            <a href="https://app.saffron.finance/" target="_blank">
              <Button ml>Launch App</Button>
            </a>
            <IconBg ml onClick={() => setIsKebabOpen(!isKebabOpen)}>
              <KebabIcon />
            </IconBg>
          </ButtonDiv>
        </SubContainer>
      </Container>
    </Header>
  );
}

export default Nav;

const Header = styled.div`
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
`;

const Container = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  width: 100%;
  height: 66px;
`;

const HeaderLogo = styled.img`
  margin-right: 25px;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 30px;
`;

const SubContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: 1600px) {
    padding-right: 15px;
  }
`;

const Links = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.fontColorAlt};
  font-weight: 400;
  margin-right: 25px;
  font-size: 16x;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    color: ${(props) => props.theme.fontColorAltHover};
  }
  @media only screen and (max-width: 839px) {
    display: none;
  }
`;

const A = styled.a`
  text-decoration: none;
  @media only screen and (max-width: 1600px) {
    padding-left: 15px;
  }
`;

const Button = styled.button`
  font-family: "Lexend", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  width: 143px;
  height: 32px;
  background-color: #c44536;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.ml &&
    css`
      margin-left: 25px;
    `}
  @media only screen and (max-width: 446px) {
    display: none;
  }
`;

const IconBg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.cardColor};
  width: 43px;
  height: 32px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background-color: ${(props) => props.theme.cardHover};
  }
  ${(props) =>
    props.ml &&
    css`
      margin-left: 25px;
    `}
  ${(props) =>
    props.pricebg &&
    css`
      width: 93px;
    `}
`;

// Toggle

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkbox = styled.input`
  display: none;
  &:checked + label {
    &:before {
      background-color: ${(props) => props.theme.fontColorAlt};
      transform: translateX(20px);
    }
    &:after {
      transform: translateX(30px) scale(1);
    }
  }
`;

const Label = styled.label`
  position: relative;
  display: block;
  width: 45px;
  height: 25px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.cardColor};
  overflow: hidden;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:before,
  &:after {
    display: block;
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: 3.5px;
    left: 3.5px;
    transition: 0.5s ease;
  }
  &:before {
    background-color: #ffa41b;
  }
  &:after {
    background-color: ${(props) => props.theme.cardColor};
    left: -14.5px;
    transform: scale(0.00001);
  }
`;

// Mobile

const MobileIconBg = styled.div`
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.cardColor};
  width: 65px;
  height: 32px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;
  margin-left: 25px;
  display: none;
  &:hover {
    background-color: ${(props) => props.theme.cardHover};
  }
  @media only screen and (max-width: 839px) {
    display: flex;
  }
`;

const MenuText = styled.span`
  font-size: 15px;
  color: ${(props) => props.theme.fontColor};
`;
