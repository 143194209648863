import React from "react";
import styled from "styled-components";
import { lightTheme, darkTheme, Globalstyles } from "../../themes";

const DocumentationIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M22.5 27.5C23.163 27.5 23.7989 27.2366 24.2678 26.7678C24.7366 26.2989 25 25.663 25 25V10L17.5 2.5H7.5C6.83696 2.5 6.20107 2.76339 5.73223 3.23223C5.26339 3.70107 5 4.33696 5 5V25C5 25.663 5.26339 26.2989 5.73223 26.7678C6.20107 27.2366 6.83696 27.5 7.5 27.5H22.5ZM16.25 5L22.5 11.25H16.25V5ZM8.75 10H12.5V12.5H8.75V10ZM8.75 15H21.25V17.5H8.75V15ZM8.75 20H21.25V22.5H8.75V20Z"
          fill="#646775"
        />
      </svg>
    </Div>
  );
};

export default DocumentationIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
