import React from "react";
import styled from "styled-components";
import { lightTheme, darkTheme, Globalstyles } from "../../themes";

const TelegramIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M26.6924 4.80126L3.79118 13.6324C2.22827 14.2601 2.23731 15.132 3.50443 15.5208L9.3841 17.355L22.9879 8.77184C23.6312 8.38047 24.2189 8.59101 23.7358 9.01984L12.714 18.967H12.7114L12.714 18.9683L12.3084 25.0288C12.9026 25.0288 13.1648 24.7562 13.4981 24.4346L16.3539 21.6575L22.2943 26.0453C23.3896 26.6485 24.1763 26.3385 24.4488 25.0313L28.3484 6.65351C28.7475 5.05313 27.7374 4.32851 26.6924 4.80126Z"
        />
      </svg>
    </Div>
  );
};

export default TelegramIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
