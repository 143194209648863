import React from "react";
import styled from "styled-components";
import { lightTheme, darkTheme, Globalstyles } from "../../themes";

const GithubIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.534 2.5835C8.41817 2.5835 2.65088 8.35079 2.65088 15.4666C2.65088 21.1577 6.34117 25.9872 11.4613 27.6922C12.1059 27.8085 12.3384 27.4119 12.3384 27.0709C12.3384 26.7648 12.328 25.9536 12.3242 24.8802C8.7398 25.6578 7.98288 23.152 7.98288 23.152C7.39905 21.664 6.553 21.2675 6.553 21.2675C5.38405 20.4679 6.64213 20.486 6.64213 20.486C7.93638 20.5764 8.6145 21.8138 8.6145 21.8138C9.76409 23.7823 11.6318 23.214 12.3629 22.8846C12.4805 22.0515 12.8163 21.4832 13.1831 21.1615C10.3234 20.8373 7.31638 19.7317 7.31638 14.7936C7.31638 13.3896 7.81884 12.2374 8.63905 11.3384C8.50859 11.0116 8.06296 9.70058 8.76692 7.92841C8.76692 7.92841 9.84805 7.58095 12.3087 9.2472C13.3394 8.96175 14.4386 8.81837 15.5327 8.8132C16.6293 8.81837 17.7298 8.96175 18.7567 9.2472C21.2186 7.57966 22.2984 7.92841 22.2984 7.92841C23.0024 9.70058 22.5606 11.0116 22.4263 11.3384C23.253 12.2374 23.749 13.3883 23.749 14.7936C23.749 19.7446 20.7394 20.8322 17.8693 21.1512C18.3278 21.549 18.7412 22.3344 18.7412 23.5356C18.7412 25.2587 18.7257 26.6485 18.7257 27.0709C18.7257 27.4158 18.9556 27.8162 19.613 27.6896C24.7306 25.982 28.417 21.1564 28.417 15.4666C28.417 8.35079 22.6498 2.5835 15.534 2.5835Z"
        />
      </svg>
    </Div>
  );
};

export default GithubIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
