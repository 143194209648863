import styled, { css } from "styled-components";

// assets
import HeaderImage from "../../assets/GovSection/govheader.svg";

function Gov(props) {
  return (
    <Header id="Gov">
      <Container>
        <Row >
          <PreTitle>Governance</PreTitle>
          <StyledText>Governed by the Saffron Community</StyledText>
          <SubText>
            Saffron is a fully decentralized, community governed protocol
            currently with <Span>14,489</Span> individual voting addresses.
          </SubText>
          <BtnRow >
            <A href="https://gov.saffron.finance/" target="_blank">
              <Button>Governance Forum</Button>
            </A>
            <A
              href="https://academy.saffron.finance/blog/using-governance"
              target="_blank"
            >
              <Button alt>Learn More</Button>
            </A>
          </BtnRow>
        </Row>
        <HeaderDiv>
          <img src={HeaderImage} />
        </HeaderDiv>
      </Container>
    </Header>
  );
}

export default Gov;

const Header = styled.div`
  margin: 0 auto;
  max-width: 1300px;
  width: 100%;
  padding-top: 150px;
  @media only screen and (max-width: 1022px) {
    padding-top: 100px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1070px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1328px) {
    padding-left: 15px;
  }
  @media only screen and (max-width: 1070px) {
    padding-left: 0px;
  }
`;

const HeaderDiv = styled.div`
  margin-right: 50px;
  @media only screen and (max-width: 1328px) {
    padding-right: 15px;
  }
  @media only screen and (max-width: 1070px) {
    padding-right: 0px;
    padding-top: 80px;
    margin-right: 0;
  }
`;

const PreTitle = styled.span`
  color: ${(props) => props.theme.fontColorAlt};
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
`;

const StyledText = styled.h1`
  color: ${(props) => props.theme.fontColor};
  font-weight: 500;
  font-size: 28px;
  margin: 0;
  ${(props) =>
    props.alt &&
    css`
      font-size: 21px;
      font-weight: 400;
      margin-top: 25px;
    `}
`;

const SubText = styled.p`
  color: ${(props) => props.theme.fontColorAlt};
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  width: 440px;
  ${(props) =>
    props.alt &&
    css`
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
      width: 300px;
    `}
  @media only screen and (max-width: 1070px) {
    width: 600px;
    text-align: center;
  }
  @media only screen and (max-width: 622px) {
    width: auto;
    padding: 0px 10px 0px 10px;
  }
`;

const Span = styled.span`
  color: #c44536;
  font-weight: 500;
`;

const BtnRow = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1070px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  @media only screen and (max-width: 361px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  font-family: "Lexend", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: black;
  border-radius: 5px;
  border: none;
  width: 160px;
  height: 40px;
  background-color: #ffffff;
  transition: 0.4s;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  ${(props) =>
    props.alt &&
    css`
      background-color: #c44536;
      margin-left: 25px;
      color: white;
      @media only screen and (max-width: 361px) {
        margin-left: 0px;
      }
    `}
`;


const A = styled.a`
margin-top: 45px;
width: 160px;F
`