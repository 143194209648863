import React from "react";
import styled from "styled-components";
import { lightTheme, darkTheme, Globalstyles } from "../../themes";

const CommunityIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M15 2.5C8.1075 2.5 2.5 8.1075 2.5 15C2.5 21.8925 8.1075 27.5 15 27.5C21.8925 27.5 27.5 21.8925 27.5 15C27.5 8.1075 21.8925 2.5 15 2.5ZM24.9137 13.75H21.4587C21.3045 11.0141 20.5391 8.34746 19.2188 5.94625C20.749 6.66131 22.0736 7.75175 23.0693 9.11614C24.065 10.4805 24.6995 12.0746 24.9137 13.75V13.75ZM15.6625 5.03375C16.9562 6.73875 18.6962 9.75875 18.9462 13.75H11.2875C11.4612 10.505 12.53 7.465 14.3512 5.0325C14.5662 5.02 14.7813 5 15 5C15.2238 5 15.4425 5.02 15.6625 5.03375ZM10.86 5.90875C9.63 8.2725 8.92 10.9525 8.7875 13.75H5.08625C5.30221 12.0598 5.94579 10.4526 6.95612 9.08052C7.96644 7.70841 9.3101 6.61674 10.86 5.90875V5.90875ZM5.08625 16.25H8.80375C8.97375 19.2237 9.635 21.8475 10.7487 24.0375C9.22649 23.3199 7.90966 22.2302 6.91995 20.8691C5.93024 19.508 5.29958 17.9193 5.08625 16.25V16.25ZM14.3125 24.9662C12.5612 22.8437 11.5275 19.87 11.3013 16.25H18.9425C18.6825 19.7163 17.5462 22.745 15.6887 24.965C15.4612 24.98 15.2338 25 15 25C14.7675 25 14.5412 24.98 14.3125 24.9662ZM19.3263 24.0013C20.52 21.7588 21.2488 19.125 21.44 16.25H24.9125C24.7015 17.9053 24.0799 19.4816 23.1043 20.8354C22.1286 22.1892 20.8299 23.2775 19.3263 24.0013V24.0013Z"
          fill="#646775"
        />
      </svg>
    </Div>
  );
};

export default CommunityIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
