import React from "react";
import styled from "styled-components";
import { lightTheme, darkTheme, Globalstyles } from "../../themes";

const TwitterIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M25.359 10.3297C25.3758 10.5557 25.3758 10.7804 25.3758 11.0052C25.3758 17.8833 20.1406 25.809 10.5733 25.809C7.62567 25.809 4.88734 24.9552 2.58301 23.4724C3.00151 23.5202 3.40451 23.5369 3.8398 23.5369C6.27201 23.5369 8.51047 22.7154 10.2994 21.314C8.01188 21.2662 6.09505 19.7679 5.43372 17.7064C5.75534 17.7542 6.07826 17.7864 6.41667 17.7864C6.88297 17.7864 7.35184 17.7219 7.78713 17.6095C5.40142 17.1264 3.61505 15.0326 3.61505 12.5035V12.4389C4.30867 12.8252 5.11338 13.0667 5.96588 13.099C4.56442 12.1664 3.64605 10.5712 3.64605 8.76674C3.64605 7.80057 3.90309 6.91449 4.35388 6.14207C6.91526 9.29761 10.7657 11.3604 15.0825 11.5864C15.0024 11.1989 14.9533 10.7972 14.9533 10.3942C14.9533 7.52674 17.2731 5.19141 20.1561 5.19141C21.6545 5.19141 23.0068 5.81916 23.9575 6.83441C25.1329 6.60836 26.2593 6.17307 27.259 5.57761C26.8728 6.78532 26.05 7.80057 24.9715 8.44511C26.019 8.33145 27.0343 8.04211 27.9668 7.63911C27.259 8.66986 26.3742 9.58824 25.359 10.3297Z"
        />
      </svg>
    </Div>
  );
};

export default TwitterIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
