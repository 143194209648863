import React, { useState } from "react";
import styled from "styled-components";

// Icons
import AcademyIcon from "../../assets/Socials/Academy";
import BackIcon from "../../assets/Socials/Back";
import CommunityIcon from "../../assets/Socials/Community";
import DocumentationIcon from "../../assets/Socials/Documentation";
import GovernanceForumIcon from "../../assets/Socials/GovernanceForum";
import GovernanceIcon from "../../assets/Socials/GovernanceIcon";
import KogeIcon from "../../assets/Socials/KogeCoin";
import PodcastIcon from "../../assets/Socials/Podcast";
import ForwardIcon from "../../assets/Socials/Forward";
import PortionIcon from "../../assets/Socials/Portion";
import SaffronAppIcon from "../../assets/Socials/SaffronAppIcon";
import VoteIcon from "../../assets/Socials/VoteIcon";

// Socials
import Discord from "../../assets/Socials/Discord";
import Github from "../../assets/Socials/Github";
import Medium from "../../assets/Socials/Medium";
import Telegram from "../../assets/Socials/Telegram";
import Twitter from "../../assets/Socials/Twitter";
import Youtube from "../../assets/Socials/Youtube";

function KebabMenu(props) {
  const [isCommunityOpen, setIsCommunityOpen] = useState(false);
  const [isGovernanceOpen, setIsGovernanceOpen] = useState(false);
  const [isPortfolioOpen, setIsPortfolioOpen] = useState(false);

  return (
    <Kebab>
      <KebabBody isKebabOpen={props.isKebabOpen}>
        <KebabItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://app.saffron.finance/"
          target="_blank"
        >
          <KebabText>Launch App</KebabText>
          <SaffronAppIcon width={"20px"} />
        </KebabItem>
        <KebabItem
          onClick={() => setIsCommunityOpen(!isCommunityOpen)}
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
        >
          <KebabText>Socials</KebabText>
          <ForwardIcon />
        </KebabItem>
        <KebabItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://academy.saffron.finance/"
          target="_blank"
        >
          <KebabText>Saffron Academy</KebabText>
          <AcademyIcon width={"20px"} />
        </KebabItem>
        <KebabItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://docs.saffron.finance/"
          target="_blank"
        >
          <KebabText>Documentation</KebabText>
          <DocumentationIcon width={"20px"} />
        </KebabItem>
        <KebabItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://academy.saffron.finance/podcast"
          target="_blank"
        >
          <KebabText>Saffron Podcast</KebabText>
          <PodcastIcon width={"20px"} />
        </KebabItem>
        <KebabItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          onClick={() => setIsGovernanceOpen(!isGovernanceOpen)}
        >
          <KebabText>Governance</KebabText>
          <ForwardIcon />
        </KebabItem>
        <KebabItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          onClick={() => setIsPortfolioOpen(!isPortfolioOpen)}
        >
          <KebabText>Portfolio Apps</KebabText>
          <ForwardIcon />
        </KebabItem>

        {/* Portfolio Start */}

        <PortolioItem
          onClick={() => setIsPortfolioOpen(!isPortfolioOpen)}
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
        >
          <BackIcon width={"30px"} />
        </PortolioItem>

        <PortolioItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://portion.io/"
          target="_blank"
        >
          <KebabText>Portion</KebabText>
          <PortionIcon width={"22px"} />
        </PortolioItem>

        <PortolioItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://kogecoin.io/"
          target="_blank"
        >
          <KebabText>Kogecoin</KebabText>
          <KogeIcon width={"22px"} />
        </PortolioItem>

        {/* Governance Start */}

        <GovernanceItem
          onClick={() => setIsGovernanceOpen(!isGovernanceOpen)}
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
        >
          <BackIcon width={"30px"} />
        </GovernanceItem>

        <GovernanceItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://gov.saffron.finance/"
          target="_blank"
        >
          <KebabText>Governance Forum</KebabText>
          <GovernanceForumIcon width={"20px"} />
        </GovernanceItem>

        <GovernanceItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://vote.saffron.finance/#/"
          target="_blank"
        >
          <KebabText>Vote</KebabText>
          <VoteIcon width={"20px"} />
        </GovernanceItem>

        {/* Community Start */}

        <CommunityItem
          onClick={() => setIsCommunityOpen(!isCommunityOpen)}
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
        >
          <BackIcon width={"30px"} />
        </CommunityItem>
        <CommunityItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://t.me/saffronfinance"
          target="_blank"
        >
          <KebabText>Telegram</KebabText>
          <Telegram width={"20px"} />
        </CommunityItem>
        <CommunityItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://discord.com/invite/pDXpXKY"
          target="_blank"
        >
          <KebabText>Discord</KebabText>
          <Discord width={"20px"} />
        </CommunityItem>
        <CommunityItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://twitter.com/saffronfinance_"
          target="_blank"
        >
          <KebabText>Twitter</KebabText>
          <Twitter width={"20px"} />
        </CommunityItem>
        <CommunityItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://github.com/saffron-finance/saffron"
          target="_blank"
        >
          <KebabText>Github</KebabText>
          <Github width={"20px"} />
        </CommunityItem>
        <CommunityItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://medium.com/saffron-finance"
          target="_blank"
        >
          <KebabText>Medium</KebabText>
          <Medium width={"20px"} />
        </CommunityItem>
        <CommunityItem
          isCommunityOpen={isCommunityOpen}
          isGovernanceOpen={isGovernanceOpen}
          isPortfolioOpen={isPortfolioOpen}
          href="https://www.youtube.com/channel/UCk_ZDXcc9Z56p9HWp7tFArA"
          target="_blank"
        >
          <KebabText>Youtube</KebabText>
          <Youtube width={"20px"} />
        </CommunityItem>

        {/* Community End */}
      </KebabBody>
    </Kebab>
  );
}

export default KebabMenu;

// styles

// kebab

const Kebab = styled.div`
  z-index: 100;
`;

const KebabItem = styled.a`
  cursor: pointer;
  text-decoration: none;
  ${props => {
    if (props.isCommunityOpen) {
      return `
        display: none;
    `
    } else if (props.isGovernanceOpen) {
      return `
        display: none;
    `
    } else if (props.isPortfolioOpen) {
      return `
        display: none;
    `
    } else {
      return `
        display: flex;
    `
    }
  }}
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 15px;
  &:hover {
    filter: ${(props) => props.theme.filterColor};
  }
`;



const CommunityItem = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: ${({ isCommunityOpen }) => (isCommunityOpen ? "flex" : "none")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 15px;
  &:hover {
    filter: ${(props) => props.theme.filterColor};
  }
`;

const GovernanceItem = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: ${({ isGovernanceOpen }) => (isGovernanceOpen ? "flex" : "none")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 15px;
  &:hover {
    filter: ${(props) => props.theme.filterColor};
  }
`;

const PortolioItem = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: ${({ isPortfolioOpen }) => (isPortfolioOpen ? "flex" : "none")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1px;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-left: 15px;
  &:hover {
    filter: ${(props) => props.theme.filterColor};
  }
`;


const KebabBody = styled.div`
  position: absolute;
  margin-top: 25px;
  margin-left: 60px;
  width: 220px;
  border-radius: 10px;
  background: ${(props) => props.theme.cardColor};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  visibility: ${({ isKebabOpen }) => (isKebabOpen ? "visible" : "hidden")};
  @media only screen and (max-width: 446px) {
    margin-left: -105px;
  }
`;

const KebabText = styled.div`
  padding-left: 10px;
  font-size: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.fontColorAlt};
`;
