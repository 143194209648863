import React from "react";
import styled from "styled-components";
import { lightTheme, darkTheme, Globalstyles } from "../../themes";

const KogeIcon = (props) => {
  return (
    <Div mr={props.mr}>
      <svg
        width={props.width}
        height="auto"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Path
          d="M17.8101 16.5325L16.7613 15.48L15.1607 17.0806C15.0982 17.143 15.0135 17.178 14.9252 17.178C14.8369 17.178 14.7522 17.143 14.6897 17.0806L13.0995 15.4903L12.0499 16.5406L14.9252 19.4151L17.8101 16.5325Z"
          fill="#646775"
        />
        <Path
          d="M4.41956 13.015H4.81286V11.4878L2.18127 9.92495C2.12544 9.89185 2.07929 9.84467 2.04744 9.78812C2.0156 9.73157 1.99917 9.66765 1.9998 9.60276L2.05387 4.2344C2.05451 4.16928 2.0723 4.10548 2.10546 4.04943C2.13862 3.99339 2.18597 3.94708 2.24274 3.91517L6.07051 1.76723C6.1272 1.7354 6.19127 1.71906 6.25628 1.71984C6.32128 1.72062 6.38494 1.7385 6.44085 1.77168L8.33399 2.89601L9.35019 1.87981L6.09421 0L0 3.52262V6.62083L0.975459 5.64463V11.0611L4.41956 13.015Z"
          fill="#646775"
        />
        <Path
          d="M6.2468 2.51807L2.79306 4.45565L2.74344 9.39665L4.81731 10.6262V6.571C4.81722 6.52233 4.82673 6.47413 4.84528 6.42914C4.86384 6.38415 4.89108 6.34326 4.92545 6.3088L7.79405 3.43649L6.2468 2.51807Z"
          fill="#646775"
        />
        <Path
          d="M20.5676 1.92574L21.5845 2.94267L23.5592 1.76946C23.6151 1.73627 23.6787 1.71839 23.7437 1.71761C23.8087 1.71683 23.8728 1.73318 23.9295 1.76501L27.7573 3.91295C27.814 3.94485 27.8614 3.99117 27.8945 4.04721C27.9277 4.10326 27.9455 4.16706 27.9461 4.23218L28.0002 9.60053C28.0008 9.66543 27.9844 9.72935 27.9526 9.7859C27.9207 9.84245 27.8746 9.88963 27.8187 9.92273L25.056 11.5633V13.0128H25.5797L29.0238 11.0589V5.64463L30 6.62083V3.52262L23.9073 0L20.5676 1.92574Z"
          fill="#646775"
        />
        <Path
          d="M27.258 9.39591L27.2084 4.45491L23.754 2.51807L22.1245 3.48538L24.9479 6.3088C24.9823 6.34326 25.0095 6.38415 25.0281 6.42914C25.0466 6.47413 25.0561 6.52234 25.056 6.571V10.7039L27.258 9.39591Z"
          fill="#646775"
        />
        <Path
          d="M19.9084 17.584L24.3169 13.1756V6.72437L19.7551 2.1626H10.1161L5.55429 6.72437V13.1756L9.96274 17.584L12.5758 14.9687L12.1151 14.508C12.0601 14.4532 12.026 14.3808 12.0187 14.3034C12.0114 14.2261 12.0314 14.1486 12.0751 14.0844L14.6497 10.307C14.6803 10.2621 14.7213 10.2253 14.7694 10.1999C14.8174 10.1745 14.8709 10.1612 14.9252 10.1612C14.9795 10.1612 15.033 10.1745 15.0811 10.1999C15.1291 10.2253 15.1702 10.2621 15.2007 10.307L17.7746 14.0844C17.8183 14.1486 17.8383 14.2261 17.831 14.3034C17.8237 14.3808 17.7896 14.4532 17.7346 14.508L17.285 14.9613L19.9084 17.584ZM19.8381 11.3061C19.9931 11.3061 20.1446 11.3521 20.2734 11.4382C20.4023 11.5243 20.5027 11.6467 20.562 11.7899C20.6214 11.9331 20.6369 12.0906 20.6066 12.2426C20.5764 12.3947 20.5018 12.5343 20.3922 12.6439C20.2826 12.7535 20.143 12.8281 19.9909 12.8583C19.8389 12.8886 19.6814 12.8731 19.5382 12.8137C19.395 12.7544 19.2726 12.654 19.1865 12.5251C19.1004 12.3963 19.0544 12.2448 19.0544 12.0898C19.0544 11.9868 19.0747 11.8848 19.1142 11.7897C19.1536 11.6946 19.2114 11.6082 19.2842 11.5354C19.3571 11.4626 19.4435 11.4049 19.5387 11.3656C19.6339 11.3262 19.7358 11.306 19.8388 11.3061H19.8381ZM9.56723 11.3061C9.77079 11.3124 9.96392 11.3977 10.1057 11.5439C10.2475 11.6901 10.3268 11.8857 10.3268 12.0894C10.3268 12.2931 10.2475 12.4887 10.1057 12.6349C9.96392 12.7811 9.77079 12.8664 9.56723 12.8727V12.8727C9.36367 12.8664 9.17054 12.7811 9.02876 12.6349C8.88698 12.4887 8.80769 12.2931 8.80769 12.0894C8.80769 11.8857 8.88698 11.6901 9.02876 11.5439C9.17054 11.3977 9.36367 11.3124 9.56723 11.3061Z"
          fill="#646775"
        />
      </svg>
    </Div>
  );
};

export default KogeIcon;

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.mr || "0"};
`;

const Path = styled.path`
  fill: ${(props) => props.theme.fontColorAlt};
`;
